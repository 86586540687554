/* eslint-disable max-len */
import Head from "next/head";
import Link from "@Components/Link";
import Button from "@Components/button";
import EmptyLayout from "@Components/layout/EmptyLayout";
import NextSeoCustom from "@Components/nextSeo";
import Headerv2 from "@Components/headerv2";
import LayoutContainer from "../components/Container";

const ContentNotFound = ({ ...props }) => {
   return (
      <>
         <Head>
            <meta name="robots" content="noindex, nofollow" />
         </Head>

         <Headerv2 {...(props as any)} />
         <NextSeoCustom
            title="Nội dung không tồn tại - lỗi 404 | Space T"
            description="Space T là Nền tảng kết nối Chủ nhà với các Công ty Nội thất uy tín, cùng hàng ngàn ưu đãi cho hơn 8000+ sản phẩm Nội thất, Trang trí, Gia dụng."
         />
         <LayoutContainer className="empty-style">
            <svg
               xmlns="http://www.w3.org/2000/svg"
               width="116"
               height="104"
               viewBox="0 0 116 104"
               fill="none"
            >
               <path
                  d="M103.461 49.6632C103.461 72.4844 84.9607 90.9846 62.1395 90.9846C39.3183 90.9846 20.8181 72.4844 20.8181 49.6632C20.8181 26.842 39.3183 8.3418 62.1395 8.3418C84.9607 8.3418 103.461 26.842 103.461 49.6632Z"
                  stroke="#E2E4EB"
                  strokeWidth="6"
               />
               <path
                  d="M86.5978 85.5234L96.7927 75.3285L110.173 88.7086C112.988 91.5238 112.988 96.0882 110.173 98.9035C107.358 101.719 102.793 101.719 99.9778 98.9035L86.5978 85.5234Z"
                  fill="#E2E4EB"
               />
               <path
                  d="M34.412 72.7356L45.8929 64.1917L46.6939 57.5168L57.1067 48.1719L57.9077 34.0211L63.2477 27.8802L61.3787 23.0742"
                  stroke="#E0E0E0"
               />
               <path
                  d="M46.2767 63.9827L34.5289 72.5266C48.2347 81.9604 76.2564 84.7028 85.2582 66.9196C94.0864 49.4792 72.6204 26.2472 61.4955 22.8652L63.6315 27.9382L58.5585 33.2781L57.2236 48.2299L46.8107 57.3078L46.2767 63.9827Z"
                  fill="#FCFCFC"
               />
               <path
                  d="M76.6653 53.0465L73.812 46.7747"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
               />
               <path
                  d="M78.6643 49.5785L71.8061 50.2434"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
               />
               <path
                  d="M67.7188 68.5426L64.8655 62.2708"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
               />
               <path
                  d="M69.7178 65.0746L62.8596 65.7395"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
               />
               <ellipse
                  cx="62.4605"
                  cy="52.5573"
                  rx="5.87392"
                  ry="3.20396"
                  transform="rotate(120 62.4605 52.5573)"
                  fill="#334750"
               />
               <ellipse
                  cx="72.309"
                  cy="41.9065"
                  rx="4.00495"
                  ry="0.533993"
                  transform="rotate(120 72.309 41.9065)"
                  fill="#D9D9D9"
               />
               <ellipse
                  cx="57.3586"
                  cy="67.8029"
                  rx="4.00495"
                  ry="0.533993"
                  transform="rotate(120 57.3586 67.8029)"
                  fill="#D9D9D9"
               />
               <path
                  d="M1.263 55.538L3.93296 58.7419L9.97647 57.2865L22.6227 63.5479L29.7798 52.3864L34.9046 54.737L43.2461 49.6641L43.9824 50.2085"
                  stroke="#E0E0E0"
                  strokeLinecap="round"
               />
               <path
                  d="M23.7479 81.4372C2.17457 81.4372 -0.370793 63.9935 1.05319 55.2716L3.99015 58.7425L9.86407 57.4075L22.6799 63.5485L29.8888 52.6016L34.9617 54.7376L43.2386 49.9316C43.4166 49.9316 43.9328 49.985 44.5736 50.1986C44.5736 51.5336 50.7145 81.4372 23.7479 81.4372Z"
                  fill="#FCFCFC"
               />
            </svg>
            <div className="text">
               <div>Rất tiếc, trang không thể truy cập được. </div>
               <div>Chúng tôi xin lỗi vì sự bất tiện này.</div>
            </div>
            <Link href={"/"}>
               <Button>Quay lại trang chủ</Button>
            </Link>
         </LayoutContainer>
      </>
   );
};
ContentNotFound.Layout = EmptyLayout;

export default ContentNotFound;
